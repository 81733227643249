import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CheckBoxEmpty from '../../../../images/svg/checkbox-empty.svg';
import CheckBoxChecked from '../../../../images/svg/checkbox-checked.svg';

import styles from './index.module.css';

export const FormCheckBox = ({
  name,
  onChange,
  children,
  value,
  'data-testid': dataTestId,
  ...rest
}) => {
  return (
    <label
      htmlFor={name}
      className="flex items-center text-sm"
      data-testid={dataTestId}
    >
      {!value && <CheckBoxEmpty />}
      {value && <CheckBoxChecked className={styles.checkbox} />}
      <Field
        id={name}
        name={name}
        onChange={onChange}
        type="checkbox"
        className={styles.hiddenCheckbox}
        {...rest}
      />
      <span className="ml-3">{children}</span>
    </label>
  );
};

FormCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  value: PropTypes.bool,
};
