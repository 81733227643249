import React from 'react';
import PropTypes from 'prop-types';
import { FormInput, formInputProps } from '../FormInput';
import classNames from 'classnames';

export const FormPhoneInput = ({
  errors,
  name,
  touched,
  prefixTest,
  prefix,
  className,
  ...rest
}) => {
  const hasError = errors[name] && touched[name];
  const containerCss = classNames(
    'flex bg-white border border-solid border-grey-2 rounded-full',
    { 'border-red text-red': hasError }
  );
  const inputCss = classNames('py-4 pr-8 pl-2 rounded-r-full', className);
  const prefixCss = classNames('flex items-center pl-8 select-none', {
    'text-grey-2': !hasError,
    'color-red': hasError,
  });

  return (
    <div className={containerCss} data-testid="form-group-field-container">
      {prefix && (
        <span className={prefixCss} {...prefixTest}>
          {prefix}
        </span>
      )}
      <FormInput
        errors={errors}
        touched={touched}
        name={name}
        className={inputCss}
        unstyled
        {...rest}
      />
    </div>
  );
};

FormPhoneInput.propTypes = {
  ...formInputProps,
  type: PropTypes.string.isRequired,
  prefixTest: PropTypes.object,
  prefix: PropTypes.string.isRequired,
};
